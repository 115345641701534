<template>
  <div>
    <Managetips />
  </div>
</template>
<script>
import Managetips from "@/components/tips/Managetips";
export default {
  components: {
    Managetips,
  },
  created() {
  },
};
</script>